import logo from './logo.svg';

import IndexTr from "./pages/IndexTr";
import IndexEn from "./pages/IndexEn";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<IndexTr />} />
          <Route index element={<IndexTr />} />
          <Route path="/en" element={<IndexEn />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
