import React from 'react'
import { Link } from "react-router-dom";
function IndexEn() {
    return (
        <div className="container pt-3 pb-5 pb-md-0">
            <div className="row pb-1 language_area">
                <div className="col-md-12" style={{ textAlign:'right' }}>
                    <Link to="/"><img src="/assets/tr.png" /></Link>
                </div>
            </div>
            <div className="row pb-3 text-center">
                <h1 style={{ fontWeight:'bold'}}><span style={{ color:'red' }}>(</span>re<span style={{ color:'red' }}>)</span>PAZARCIK</h1>
            </div>

            <div className="frame row color_black ">

                <div className="text_area p-3" >



                    <div className="col-md-12" style={{ fontSize:'16px' }}>
                        <br />
                        Dear Friends and Supporters,<br /><br />
                        The recent earthquakes in our hometown of Pazarcik have caused widespread
                        destruction and loss, and our hearts go out to all those affected. We are working
                        tirelessly to provide support and assistance to those in need.<br />
                        <br />
                        The people of Greater Pazarcik are coming together to help revive our city, towns,
                        villages, and rebuild our community. We are addressing the immediate emergencies and
                        developing short and long-term plans to mitigate the impact of the earthquakes.<br />
                        <br />
                        We are proud of our hometown and its people, and we are committed to supporting our
                        community during this difficult time. We understand that we cannot do this alone and
                        need the support and help of our friends, neighbors, and the wider global community.
                        That is why we are launching a new website for the (Re)Pazarcik Foundation, an
                        organization platform dedicated to supporting our community and providing
                        information and resources to help us move forward. This website will serve as a hub for
                        all things related to the relief efforts and will provide a central location for information,
                        updates, and opportunities to get involved.<br />
                        <br />
                        Together, we are strong. We hope you will stand with us and be a part of the efforts to
                        revive Pazarcik. Please visit our website to learn more about how you can help support
                        our community.<br />
                        <br />
                        Together, we will rise again and rebuild a better, stronger Pazarcik.<br />
                        <br />
                        Sincerely,<br />

                        The People of Pazarcik<br />

                    </div>

                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6 text-center">
                            <br />
                            www.repazarcik.org<br />
                            <br />
                            <h4>Coming Soon !</h4>
                            <br />
                            Revive Pazarcik Foundation

                            <br />
                            <br />

                        </div>
                        <div className="text-right col-md-3 " style={{ textAlign:'right' }}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IndexEn;
